

  .navBar {
    display: block;
    margin: 0;
    padding: 0;
    overflow: hidden;
    background-color: #333;
  }

.navButton{
    display: inline-block;
    text-decoration: none;
}

.navLink{

    color: white;
    text-align: center;
    padding: .5rem .5rem;
}

.navLink:hover {
    background-color: #111;
  }

  .scrolled{
    position: -webkit-sticky; /* Safari */
    position: sticky;
    background-color: white;
    top: 0;
  }

