.gridHeader{
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 3fr 1.2fr 3.5fr 5fr;
    margin-left: 2rem;
    margin-right: 2rem;
    background-color: rgb(90, 90, 90);
    font-size: 14pt;
    font-weight: bold;
    padding-top: .5rem;
    padding-bottom: .5rem;
    padding-left: .25rem;
    border-width: 1px 1px 1px 1px;
    border-color: black;
    border-style: solid;
    color: white;
    min-width: 1300px;
}

.createNewListingButton{
    font-size: 16pt;
    margin-top: 1rem;
    margin-bottom: 1rem;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: .5rem;
    padding-bottom: .5rem;
    margin-left: 2rem;
    background-color: rgb(0, 163, 35);
    color: white;
    border-radius: 15px;
    font-weight: bold;
    cursor: pointer;
}

.logoutButton{
    font-size: 14pt;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    margin-left: 2rem;
    color: rgb(255, 0, 0);
    background-color: transparent;
    cursor: pointer;
}

.warning{
    font-size: 14pt;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    margin-left: 2rem;
    color: rgb(255, 0, 0);
    font-weight: bold;
}

.newListingHeader{
    font-weight: bold;
    margin-left: 2rem;
    margin-top: 2rem;
    margin-bottom: 1rem;
    font-size: 20pt;
}

.backButton{
    margin-left: 2rem;
    margin-bottom: 2rem;
    font-size: 16pt;
    display: inline-block;
    padding: 8px 12px; 
    cursor: pointer;
    border-radius: 15px;
    background-color: rgb(54, 107, 255);
    font-size: 16px;
    color: #fff;
    text-decoration: none;
    margin-left: 2rem;
    margin-bottom: 1.5rem;
}

.dateInput{
    margin-left: 2rem;
    display: block;
    height: 1.5rem;
    margin-bottom: 2rem;
    font-size: 16pt;
    width: 300px;
}

.timeInput{
    margin-left: 2rem;
    display: block;
    height: 1.5rem;
    margin-bottom: 3rem;
    font-size: 12pt;
    width: 300px;
}

.inputLabels{
    margin-left: 2rem;
    display: block;
    font-size: 16pt;
}

.inputFormat{
    margin-left: 2rem;
    display: block;
    height: 2rem;
    margin-bottom: 2rem;
    font-size: 16pt;
}

.titleInput{
    margin-left: 2rem;
    display: block;
    height: 2rem;
    margin-bottom: 2rem;
    font-size: 16pt;
    width: 80vw;
}

.textArea{
    resize: none;
    margin-top: 0;
    margin-left: 2rem;
    margin-right: 2rem;
    margin-bottom: 2rem;
    display: block;
    width: 80vw;
    height: 10rem;
    font-size: 16pt;
}

.submitButton{
    margin-left: 2rem;
    margin-right: 2rem;
    padding-left: 2rem;
    padding-right: 2rem;
    margin-bottom: 3rem;
    background-color: rgb(54, 107, 255);
}

.chooseFileButton{
    margin-top: 1rem;
    margin-right: 2rem;
    padding-left: 2rem;
    padding-right: 2rem;
    margin-bottom: 3rem;
}


.new-button {
    display: inline-block;
    padding: 8px 12px; 
    cursor: pointer;
    border-radius: 15px;
    background-color: rgb(117, 117, 117);
    font-size: 16px;
    color: #fff;
    text-decoration: none;
    margin-left: 2rem;
    margin-bottom: 1.5rem;
}

input[type="file"] {
    margin-left: 2rem;
    margin-bottom: 1.5rem;
  position: absolute;
  z-index: -1;
  top: 6px;
  left: 0;
  font-size: 15px;
  color: rgba(153, 153, 153, 0);
}
.button-wrap {
  position: relative;

}

.bannerBoxSection{
    margin-top: 4rem;
    margin-left: 2rem;
    margin-right: 2rem;
    margin-bottom: 4rem;
    padding: 1rem 2rem 1rem 2rem;
    background-color: rgb(233, 233, 233);
    border-color: black;
    border-style: solid;
    border-width: 1px;
}

.bannerInputTitle{
    width: 100%;
    height: 100%;
    margin: 0px;
    box-sizing: border-box;
    font-size: 16pt;

}

.bannerInputDescription{
    display: block;
    resize: none;
    font-size: 16pt;
    margin: 0px;
    width: 100%;
    height: 10rem;
}

.bannerInputExpire{
    display: block;
    resize: none;
    font-size: 16pt;
}

.bannerInputTitle{
    font-size: 16pt;
    font-weight: bold;
    color: rgb(90, 90, 90);
    margin-bottom: .25rem;
    margin-top: 1rem;
}

.dashboardSectionTitle{
    font-size: 26pt;
    font-weight: bold;
    color: red;
    text-align: center;
    margin-top: 6rem;
}

/* Solid border */
hr.solid {
    border-top: 3px solid #bbb;
  }

  .subscriberText{
    text-align: center;
    font-size: 16pt;
    font-weight: bold;
    color: #bbb;
  }

  .subscriberCount{
    text-align: center;
    font-size: 30pt;
    font-weight: bold;
    color: #ff0000;
  }