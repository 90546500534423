.gridListing{
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 3fr 1.2fr 3.5fr 5fr;
    margin-left: 2rem;
    margin-right: 2rem;
    background-color: rgb(233, 233, 233);
    padding-top: .5rem;
    padding-bottom: .5rem;
    padding-left: .25rem;
    border-width: 0px 1px 1px 1px;
    border-color: black;
    border-style: solid;
    min-width: 1300px;
    font-weight: bold;

}

.deleteButton{
    color: red;
	background: none;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
}