.pictureListing{
    margin: 0 auto;
    margin-left: 2rem;
    margin-right: 2rem;
    margin-bottom: 1rem;
    background-color: rgb(233, 233, 233);
    padding-top: .5rem;
    padding-bottom: .5rem;
    padding-left: .25rem;
    border-width: 1px 1px 1px 1px;
    border-color: black;
    border-style: solid;
    font-weight: bold;
    text-overflow: clip;
}

.deleteButton{
    color: red;
	background: none;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
    margin-top: 1rem;
    margin-bottom: 1rem;
    margin-left: 1rem;
}

.pictureName{
    font-size: 12pt;
    font-weight: normal;
    margin-bottom: 1rem;
    margin-left: 1rem;
}

.descriptionInput{
    font-size: 14pt;
    font-weight: normal;
    width: 80%;
    margin-left: 1rem;
}

