.loginBox{
    margin: 0 auto;
    text-align: center;
    background-color: rgb(231, 231, 231);
    margin-top: 10%;
    padding-top: 1rem;
    padding-bottom: 2rem;

}

.inputText{
    display: block;
    margin: 0 auto;
    text-decoration: none;
    margin-top: 1.5rem;
    border-radius: 15px;
}

.loginButton{
    display: block;
    background-color: rgb(87, 87, 87);
    margin: 0 auto;
    margin-top: 2rem;
    margin-left: 15%;
    margin-right: 15%;
    border-radius: 15px;
    font-size: 16pt;
    text-decoration: none;
    color: white;
    padding-top: .5rem;
    padding-bottom: .5rem;
    cursor: pointer;
}

.exitButton{
    display: block;
    margin: 0 auto;
    color: red;
    margin-top: 1rem;
    font-weight: bold;
    font-size: 14pt;
    text-decoration: none;
}

.errorMessage{
    margin: 0 auto;
    color: red;
    margin-top: 1rem;
}


/* Tabet */
@media only screen and (min-width: 600px) {
    .loginBox{
        margin-left: 20%;
        margin-right: 20%;
        border-radius: 15px;
    }

    .loginButton{
        margin-left: 30%;
        margin-right: 30%;
    }

}


/* Computer */
@media only screen and (min-width: 1000px) {
    .loginBox{
        margin-left: 20%;
        margin-right: 20%;
        border-radius: 15px;
    }

    .loginButton{
        margin-left: 35%;
        margin-right: 35%;
    }
}