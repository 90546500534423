.auctionBillContainer{
    justify-content: center;

}

.auctionBill{
    max-width:100%;
    max-height:100%;
    cursor: pointer;
}

.clickBillToExpand{
    margin: auto 0px;
    margin-top: .25rem;
    margin-bottom: 1rem;
}

.pictureView{
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 120%;
    max-height: 120%;
}

.pictureViewText{
    line-height: 22px;
    height: 3rem;
    overflow: hidden;
    white-space: pre-wrap;
    color: white;
    margin-left: 1rem;
    margin-right: 1rem;
    text-align: center;
    padding-top: 1rem;
    padding-bottom: 1rem;
    margin-bottom: 1rem;
    font-size: 14pt;

}

.auctionHeader{
    font-size: 20pt;
    font-weight: bold;
    text-align: left;
    margin-left: .5rem;
    margin-right: .5rem;

}

.live{
    color: white;
    font-size: 12pt;
    margin: .25rem 0rem 0rem 0rem;
    background-color: rgb(255, 0, 0);
    margin-left: .5rem;
    width: fit-content;
    padding-left: 3.5rem;
    padding-right: 3.5rem;
    padding-bottom: .25rem;
    padding-top: .25rem;
    border-color: rgb(255, 98, 98);
    border-style: solid;
    border-radius: 15px 15px 0px 0px;
    border-width:  2px 2px 0px 2px;
}

.liveOnline{
    color: white;
    font-size: 12pt;
    margin: .25rem 0rem 0rem 0rem;
    background-color: rgb(5, 141, 0);
    margin-left: .5rem;
    width: fit-content;
    padding-left: 3.5rem;
    padding-right: 3.5rem;
    padding-bottom: .25rem;
    padding-top: .25rem;
    border-color: rgb(86, 187, 91);
    border-style: solid;
    border-radius: 15px 15px 0px 0px;
    border-width:  2px 2px 0px 2px;

}

.online{
    color: white;
    font-size: 12pt;
    margin: .25rem 0rem 0rem 0rem;
    background-color: rgb(0, 68, 255);
    margin-left: .5rem;
    width: fit-content;
    padding-left: 3.5rem;
    padding-right: 3.5rem;
    padding-bottom: .25rem;
    padding-top: .25rem;
    border-color: rgb(98, 121, 255);
    border-style: solid;
    border-radius: 15px 15px 0px 0px;
    border-width:  2px 2px 0px 2px;
}

.auctionDate{
    font-size: 18pt;
    text-align: left;
    margin-left: .5rem;
    margin-right: .5rem;
    color: #ff0000;
    font-weight: bolder;
}

.onlineDate{
    font-size: 20pt;
    text-align: left;
    margin-left: .5rem;
    margin-right: .5rem;
    color: rgb(0, 68, 255);
    font-weight: bolder;
    margin-top: .5rem;
}

.liveDate{
    font-size: 20pt;
    text-align: left;
    margin-left: .5rem;
    margin-right: .5rem;
    color: rgb(255, 0, 0);
    font-weight: bolder;
    margin-top: .5rem;
}

.liveOnlineDate{
    font-size: 20pt;
    text-align: left;
    margin-left: .5rem;
    margin-right: .5rem;
    color: rgb(4, 131, 0);
    font-weight: bolder;
    margin-top: .5rem;
}


.onlineEndDate{
    font-size: 10pt;
    text-align: left;
    margin-left: .5rem;
    margin-right: .5rem;
    color: rgb(0, 68, 255);
    font-weight: bolder;
    margin-top: .15rem;

}

.liveEndDate{
    font-size: 10pt;
    text-align: left;
    margin-left: .5rem;
    margin-right: .5rem;
    color: rgb(255, 0, 0);
    font-weight: bolder;
    margin-top: .15rem;
}

.liveOnlineEndDate{
    font-size: 10pt;
    text-align: left;
    margin-left: .5rem;
    margin-right: .5rem;
    color: rgb(4, 131, 0);
    font-weight: bolder;
    margin-top: .15rem;
}

.auctionEndTime{
    font-size: 10pt;
    text-align: left;
    margin-left: .5rem;
    margin-right: .5rem;
    margin-bottom: 1rem;
    color: #888888;
    font-weight: bold;

}

.auctionTime{
    font-size: 12pt;
    text-align: left;
    margin-left: .5rem;
    margin-right: .5rem;
    color: #888888;
    font-weight: bold;

}

.auctionDescription{
    font-size: 10pt;
    text-align: left;
    margin-left: .5rem;
    margin-right: .5rem;
    margin-top: .5rem;
    margin-bottom: 1.5rem;
    color: #000000;
}

.divider{
    background-color: grey;
    height: 1px;
    margin-bottom: .5rem;

}

.nextButton{
    text-align: center;
    margin-top: 1rem;
    margin-bottom: 1.5rem;
    margin-left: .5rem;
    margin-right: .5rem;
    padding: .5rem;
    font-size: 14pt;
    background-color: rgb(139, 139, 139);
    border-radius: 15px;
    border-width: 2px;
    border-color: rgb(238, 238, 238);
    border-style: solid;
    color: white;
    cursor: pointer;
}

.prevButton{
    text-align: center;
    margin-top: 1rem;
    margin-bottom: 1.5rem;
    margin-left: .5rem;
    margin-right: .5rem;
    padding: .5rem;
    font-size: 14pt;
    background-color: rgb(139, 139, 139);
    border-radius: 15px;
    border-width: 2px;
    border-color: rgb(238, 238, 238);
    border-style: solid;
    color: white;
    cursor: pointer;
}


.buttonsGrid{
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.proxybidButton{
    cursor: pointer;
    display: block;
    text-align: center;
    margin-left: .5rem;
    margin-right: .5rem;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: .5rem;
    padding-bottom: .5rem;
    font-size: 14pt;
    background-color: rgb(255, 255, 255);
    border-radius: 15px;
    border-width: 2px;
    border-color: rgb(94, 3, 3);
    border-style: solid;
    color: rgb(0, 122, 41);
    text-decoration: none;
}

.proxiOnline{
    cursor: pointer;
    display: block;
    text-align: center;
    margin-left: .5rem;
    margin-right: .5rem;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: .5rem;
    padding-bottom: .5rem;
    font-size: 14pt;
    background-color: rgb(0, 68, 255);
    border-radius: 15px;
    border-width: 2px;
    border-color: rgb(98, 121, 255);
    border-style: solid;
    color: rgb(255, 255, 255);
    text-decoration: none;
}

.proxiLiveOnline{
    cursor: pointer;
    display: block;
    text-align: center;
    margin-left: .5rem;
    margin-right: .5rem;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: .5rem;
    padding-bottom: .5rem;
    font-size: 14pt;
    background-color: rgb(5, 141, 0);
    border-radius: 15px;
    border-width: 2px;
    border-color: rgb(86, 187, 91);
    border-style: solid;
    color: rgb(255, 255, 255);
    text-decoration: none;
}

.closeButton{
    cursor: pointer;
    display: block;
    text-align: center;

    margin-left: .5rem;
    margin-right: .5rem;

    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: .5rem;
    padding-bottom: .5rem;

    font-size: 14pt;
    background-color: rgb(0, 81, 255);
    border-radius: 15px;
    border-width: 2px;
    border-color: rgb(169, 169, 255);
    border-style: solid;
    color: white;
    text-decoration: none;
}

.viewPicturesButton{
    cursor: pointer;
    display: block;
    margin: 0 auto;
    margin-left: .5rem;
    margin-right: .5rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: .5rem;
    padding-bottom: .5rem;
    font-size: 14pt;
    background-color: rgb(255, 255, 255);
    border-radius: 15px;
    border-width: 2px;
    border-color: rgb(65, 65, 65);
    border-style: solid;
    color: rgb(65, 65, 65);
    text-decoration: none;
}

.helpButton{
    display: block;
    margin: 0 auto;
    margin-top: 1rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    color: #5a5a5a;
    cursor: pointer;
}


.sectionBox{
    background-color: #f6f6f6;
    display: block;
    margin-bottom: 2rem;
    padding-top: .5rem;
    padding-bottom: 1rem;
    text-align: center;
}

.overlay {
    position: fixed;
    display: block;
    overflow-y: scroll;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.945);
    cursor: pointer;
  }




/* Tabet */
@media only screen and (min-width: 600px) {
    .sectionBox{
        margin-left: 15%;
        margin-right: 15%;
        border-radius: 20px;
        box-shadow: 0px 0px 1px 1px  #929292;
    }

    .auctionHeader{
        margin-left: 1.5rem;
        margin-right: 1.5rem;
    }

    .online{
        margin-left: 1.5rem;
        }
        
        .live{
        margin-left: 1.5rem;
        }
        
        .liveOnline{
        margin-left: 1.5rem;
        }

        .onlineDate{
            margin-left: 1.5rem;
        }
        
        .liveDate{
            margin-left: 1.5rem;
        }
        
        .liveOnlineDate{
            margin-left: 1.5rem;
        }

        .onlineEndDate{
            margin-left: 1.5rem;
        }
        
        .liveEndDate{
            margin-left: 1.5rem;
        }
        
        .liveOnlineEndDate{
            margin-left: 1.5rem;
        }

        .auctionEndTime{
            margin-left: 1.5rem;
            margin-right: 1.5rem;
            margin-bottom: 1rem;
        }

    
    .auctionTime{
        margin-left: 1.5rem;
        margin-right: 1.5rem;
        margin-bottom: 1rem;
    }
    
    .auctionDescription{
        margin-left: 1.5rem;
        margin-right: 1.5rem;
        margin-top: .5rem;
        margin-bottom: 1.5rem;
    }


    .nextButton{
        margin-left: 1rem;
        margin-right: 1rem;
    }
    .prevButton{
        margin-left: 1rem;
        margin-right: 1rem;
    }
    .proxybidButton{
        margin-left: 1rem;
        margin-right: 1rem;
    }

    .closeButton{
        margin-left: 1rem;
        margin-right: 1rem;
    }

    .viewPicturesButton{
        margin-left: 1rem;
        margin-right: 1rem;
    }

    .pictureView{
        max-width: 50%;
        max-height: 50%;
    }

    .pictureViewText{
        line-height: 22px;
        height: 3rem;
        padding-top: .25rem;
        padding-bottom: .25rem;
        margin-bottom: .25rem;
    }

    .proxiOnline{
        margin-left: 1rem;
        margin-right: 1rem;
}

.proxiLiveOnline{
        margin-left: 1rem;
        margin-right: 1rem;
}

}


/* Computer */
@media only screen and (min-width: 1000px) {



    .sectionBox{
        margin-left: 25%;
        margin-right: 25%;

        border-radius: 20px;
        box-shadow: 0px 0px 1px 1px  #929292;
    }

    .auctionHeader{
        margin-left: 1.5rem;
        margin-right: 1.5rem;
    }
    
    .auctionDate{
        font-size: 22pt;
        margin-top: .5rem;
        margin-left: 1.5rem;
        margin-right: 1.5rem;
        margin-top: 1rem;
    }
    
    .auctionTime{
        margin-left: 1.5rem;
        margin-right: 1.5rem;
        margin-bottom: 1rem;
    }
    
    .auctionDescription{
        margin-left: 1.5rem;
        margin-right: 1.5rem;
        margin-top: .5rem;
        margin-bottom: 1.5rem;
    }

    .nextPrevButton{
        margin-left: 2rem;
        margin-right: 2rem;
    }

    .proxybidButton{
        margin-left: 6rem;
        margin-right: 6rem;
    }

    .nextButton{
        margin-left: 3rem;
        margin-right: 16rem;
    }
    .prevButton{
        margin-left: 16rem;
        margin-right: 3rem;
    }

    .closeButton{
        margin-left: 16rem;
        margin-right: 16rem;
    }

    .viewPicturesButton{
        margin-left: 6rem;
        margin-right: 6rem;
    }

    .pictureView{
        max-width: 35%;
        max-height: 35%;
    }

    .pictureViewText{
        line-height: 22px;
        height: 2rem;
        padding-top: .25rem;
        padding-bottom: .25rem;
        margin-bottom: .25rem;
    }

    .proxiOnline{
        margin-left: 6rem;
        margin-right: 6rem;
}

.proxiLiveOnline{
        margin-left: 6rem;
        margin-right: 6rem;
}

}