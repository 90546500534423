
.backgroundBanner2 {
    margin: 0px;
    min-height: 70vw;
    background-repeat: no-repeat;
    background-image: url('../images/background1.jpg');
    background-size: cover;
    background-attachment: fixed;
    background-position:center;

}

.noListingPic{
    padding-top: 1.5rem;
    margin: 0 auto;
    height:3rem; 
}

.noListings{
    margin: 0 auto;
    margin-left: 1rem;
    margin-right: 1rem;
    margin-top: .5rem;
    margin-bottom: 1.5rem;
    font-weight: bold;
}

.spacer{
    margin-top: 0rem;
}

.signupBox{
    background-color: #f6f6f6;
    display: block;
    margin-bottom: 2rem;
    padding-top: .5rem;
    padding-bottom: 1rem;
    text-align: center;
}


/* Tabet */
@media only screen and (min-width: 600px) {
    .signupBox{
        margin-left: 15%;
        margin-right: 15%;
    }

    .backgroundBanner2 {
        padding-bottom: 3rem;
    }

    .spacer{
        margin-top: 2rem;
    }
    
}


/* Computer */
@media only screen and (min-width: 1000px) {
    .signupBox{
        margin-left: 20%;
        margin-right: 20%;
    }

    .backgroundBanner2 {
        padding-bottom: 3rem;
    }

    .spacer{
        margin-top: 2rem;
    }
}