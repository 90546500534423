.iconFormat{
    margin-top: 1rem;
    height: 4rem;
}


.backgroundBanner2 {
    margin: 0px;
    min-height: 70vw;
    background-repeat: no-repeat;
    background-image: url('../images/background1.jpg');
    background-size: cover;
    background-attachment: fixed;
    background-position:center;
    
}


.sectionBox{
    background-color: #f6f6f6;
    display: block;

    margin-bottom: 2rem;
    padding-top: .5rem;
    padding-bottom: 1rem;
    text-align: center;
}

.pageHeader{
    margin: 0 auto;
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-size: 22pt;
    font-weight: bold;
    color: red;
}

.pageText{
    margin: 0 auto;
    margin-bottom: 1rem;
    margin-left: 1rem;
    margin-right: 1rem;
    font-size: 12pt;
}



/* Tabet */
@media only screen and (min-width: 600px) {
    .sectionBox{
        margin-top: 2rem;
        margin-left: 15%;
        margin-right: 15%;
    }
}


/* Computer */
@media only screen and (min-width: 1000px) {
    .sectionBox{
        margin-top: 2rem;
        margin-left: 20%;
        margin-right: 20%;
    }
}