.signupButton{
    font-size: 16pt;
    padding-top: .5rem;
    padding-bottom: .5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    margin: 0rem;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2rem;
    text-decoration: none;
    background-color: rgb(54, 107, 255);
    color: white;
    border-radius: 15px;
}

.overlay {
    position: fixed;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.945);
    cursor: pointer;
  }


  .notificationSuccess{
    background-color: #f6f6f6;
    display: block;
    margin-top: 50%;
    margin-bottom: 2rem;
    padding-top: .5rem;
    padding-bottom: 1rem;
    text-align: center;
  }

  .notificationHeader{
    margin: 0 auto;
    margin-bottom: 2rem;
    margin-top: 2rem;
    font-size: 18pt;
    font-weight: bold;
    color: green;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .notificationInfo{
    margin: 0 auto;
    margin-bottom: 2rem;
    font-size: 14pt;
    color: rgb(37, 37, 37);
    padding-left: 2rem;
    padding-right: 2rem;
  }


  /* Tabet */
@media only screen and (min-width: 600px) {

    .notificationSuccess{
        margin-left: 15%;
        margin-right: 15%;
        margin-top: 10%;
        border-radius: 20px;
        box-shadow: 0px 0px 1px 1px  #929292;
      }


}


/* Computer */
@media only screen and (min-width: 1000px) {

    .notificationSuccess{
        margin-top: 10%;
        margin-left: 20%;
        margin-right: 20%;
        border-radius: 20px;
        box-shadow: 0px 0px 1px 1px  #929292;
      }
}