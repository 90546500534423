.errorMessage{
    margin: 0 auto;
    color: red;
    padding-bottom: 1rem;
}

.backgroundBanner {
        margin: 0px;
        min-height: 70vw;
        background-repeat: no-repeat;
        background-image: url('../images/background1.jpg');
        background-size: cover;
        background-attachment: fixed;
        background-position:center;
        padding-bottom: 3rem;
}

.bannerText{
    font-size: 70pt;
    font-weight: bolder;
    padding-left: 2rem;
    color: white;
    text-shadow: 2px 2px 10px #727272;
}

.introText{
    padding: 0rem 2rem 1rem 2rem;
    margin: 0rem;
}

.introHeader{
    display: inline-block;
    font-size: 28pt;
    color: #535353;
}

.signupHeader{
    margin: 0rem;
    padding-top: 1rem;
    color: #535353;
    font-size: 16pt;
    font-weight: bold;
    padding-bottom: 1rem;
}

.signupText{
    margin-top: 0px;
    margin-left: 4rem;
    margin-right: 4rem;
    padding-bottom: .5rem;
}

.whoWeAreBullets{
    text-align: left;
    margin-right: 1rem;
}

.whoWeAreSectionHeader{
    font-weight: bold;
    margin: 1rem;
    text-align: left;
}

.whoWeAreText{
    margin-left: 1rem;
    margin-right: 1rem;
    margin-top: 1rem;

}

.whoWeAreHeader{
    font-size: 22pt;
    font-weight: bolder;
    color: rgb(255, 0, 0);
    margin-top: 1rem;
}

.signupNotification{
    margin-top: 1rem;
    height: 40px;
    background-size: 40px;
    background-repeat: no-repeat;
    background-image: url('../images/notification.jpg');
    background-position:center;
    border-radius: 20px;
}

.emailNotification{
    margin-top: 1rem;
    margin-bottom: -1rem;
    height: 60px;
    background-size: 60px;
    background-repeat: no-repeat;
    background-image: url('../images/emailpng.png');
    background-position:center;
    border-radius: 20px;
}

.bidNumber{
    display: inline-block;
    height: 50px;
    margin-right: 1rem;
    /*
    height: 60px;
    background-size: 60px;
    background-repeat: no-repeat;
    background-image: url('../images/bidNumber.png');
    background-position:center;
    border-radius: 20px; */
}

.overlay {
    position: fixed;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.945);
    cursor: pointer;
  }

  .notificationSuccess{
    background-color: #f6f6f6;
    display: block;
    margin-top: 50%;
    margin-bottom: 2rem;
    padding-top: .5rem;
    padding-bottom: 1rem;
    text-align: center;
  }

  .notificationHeader{
    margin: 0 auto;
    margin-bottom: 2rem;
    margin-top: 2rem;
    font-size: 18pt;
    font-weight: bold;
    color: green;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .notificationInfo{
    margin: 0 auto;
    margin-bottom: 2rem;
    font-size: 14pt;
    color: rgb(37, 37, 37);
    padding-left: 2rem;
    padding-right: 2rem;
  }

.whoWeAreIcon{
    margin-top: 1rem;
    height: 40px;
    background-size: 40px;
    background-repeat: no-repeat;
    background-image: url('../images/group.png');
    background-position:center;
    border-radius: 20px;
}

.byronDenise{
    width: 90%;
    margin-left: 2rem;
    margin-right: 2rem;
}

.signupInput{
    font-size: 16pt;
    padding-top: .5rem;
    padding-bottom: .5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    margin: 0rem;
    margin-bottom: 1rem;
    margin-left: auto;
    margin-right: auto;
    width: 20rem;
}

.noDashes{
    font-size: 11pt;
    color: rgb(37, 37, 37);
    background-color: yellow;
}

.signupButton{
    font-size: 16pt;
    padding-top: .5rem;
    padding-bottom: .5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    margin: 0rem;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2rem;
    text-decoration: none;
    background-color: rgb(54, 107, 255);
    color: white;
    border-radius: 15px;
}


.signupBox{
    background-color: #f6f6f6;
    display: block;
    margin-bottom: 2rem;
    padding-top: .5rem;
    padding-bottom: 1rem;
    text-align: center;
}

.bannerTitle{
    font-size: 22pt;
    text-align: center;
    font-weight: bold;
    margin-top: .5rem;
    margin-left: 1rem;
    margin-right: 1rem;
    color: red;
}

.bannerDescription{
    font-size: 12pt;
    text-align: center;
    margin-top: .5rem;
    margin-left: 1rem;
    margin-right: 1rem;
    margin-bottom: 1rem;
}


/* Tabet */
@media only screen and (min-width: 600px) {
    .signupBox{
        margin-left: 15%;
        margin-right: 15%;
        border-radius: 20px;
        box-shadow: 0px 0px 1px 1px  #929292;
    }

    .backgroundBanner {
        padding-top: 3rem;
        background-image: url('../images/auctionPic.JPG');
    }

    .notificationSuccess{
        margin-left: 15%;
        margin-right: 15%;
        margin-top: 10%;
        border-radius: 20px;
        box-shadow: 0px 0px 1px 1px  #929292;
      }


}


/* Computer */
@media only screen and (min-width: 1000px) {
    .signupBox{
        margin-left: 20%;
        margin-right: 20%;
        border-radius: 20px;
        box-shadow: 0px 0px 1px 1px  #929292;
    }

    .backgroundBanner {
        padding-top: 3rem;
        background-image: url('../images/auctionPic.JPG');
    }

    .notificationSuccess{
        margin-top: 10%;
        margin-left: 20%;
        margin-right: 20%;
        border-radius: 20px;
        box-shadow: 0px 0px 1px 1px  #929292;
      }
}