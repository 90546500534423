.iconFormat{
    margin-top: 1rem;
    height: 4rem;
}

.backgroundBanner2 {
    margin: 0px;
    min-height: 70vw;
    background-repeat: no-repeat;
    background-image: url('../images/background1.jpg');
    background-size: cover;
    background-attachment: fixed;
    background-position:center;

}


.contactHeader{
    margin: 0 auto;
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-size: 22pt;
    color: red;
    font-weight: bold;
}

.generalText{
    margin: 0 auto;
    margin-bottom: 1rem;
    margin-left: 1rem;
    margin-right: 1rem;
}

.contactName{
    margin: 0 auto;
    font-size: 14pt;
    margin-left: 5%;
    margin-right: 5%;
    margin-bottom: 1rem;
    color: rgb(75, 75, 75);
    font-family:Verdana, Geneva, Tahoma, sans-serif;
    background-color: white;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-radius: 10px 10px 10px 10px;
}

.contactPhone{
    font-weight: bold;
    color: rgb(75, 75, 75);
    font-family:Verdana, Geneva, Tahoma, sans-serif;
    text-decoration: none;
}

/* Tabet */
@media only screen and (min-width: 600px) {
    .contactName{
        margin-left: 10%;
        margin-right: 10%;
    }
}


/* Tabet */
@media only screen and (min-width: 1000px) {
    .contactName{
        margin-left: 15%;
        margin-right: 15%;
    }
}